(function () {
    'use strict';

    angular.module("common")
        .factory('aflPageInstructionsModal', aflPageInstructionsModal);

    aflPageInstructionsModal.$inject = ['$uibModal'];

    function aflPageInstructionsModal($uibModal) {

        return {
            open: open
        };

        function open(page, aerosUserGuideUrl) {
            var modal = $uibModal.open({
                size: "md",
                templateUrl: "/static/javascript/directives/afl-page-instructions-modal/afl-page-instructions-modal.html",
                windowClass: "zindex2050 afl-page-instructions-modal",
                resolve: {
                    aerosUserGuideUrl: function () {
                        return aerosUserGuideUrl;
                    }
                },
                controller: ["$scope", "$state", "aerosUserGuideUrl", "PageService",
                    function ($scope, $state, aerosUserGuideUrl, PageService) {
                    $scope.page = PageService.getPages()[$state.current.name];
                    $scope.aerosUserGuideUrl = aerosUserGuideUrl;
                }]
            });

            return modal.result;
        }
    }
})();

